<template>
  <div>
    <shared-recordings :current-user-id="provider.user_id" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import SharedRecordings from "../../../components/SharedRecordings.vue";
export default {
  name: "PatientSharedRecordings",
  components: {
    SharedRecordings,
  },
  computed: {
    ...mapState({
      provider: (state) => state.providers.provider,
    }),
  },
};
</script>
